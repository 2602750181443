/**
 * Auth
 */

import axios from 'axios';
import isBrowser from '../utils/isBrowser';

const STORAGE_USER_KEY = 'gatsbyUser';

/**
 * API endpoints
 */
const API_ROOT = `https://${process.env.GATSBY_WORDPRESS_SOURCE}/wp-json`;
const endpoints = {
  SIGN_UP: `${API_ROOT}/wp/v2/users/register`,
  SIGN_IN: `${API_ROOT}/jwt-auth/v1/token`,
  VALIDATE_AUTH: `${API_ROOT}/jwt-auth/v1/token/validate`,
}

/**
 * Get current user
 */
export const getUser = () =>
  isBrowser() && window.localStorage.getItem(STORAGE_USER_KEY)
    ? JSON.parse(window.localStorage.getItem(STORAGE_USER_KEY))
    : {}

const setUser = user => window.localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(user))

/**
 * Handle login
 */
export const handleLogin = (username, password, onSuccess, onError) => {
  axios.post(endpoints.SIGN_IN, { username, password }).then(({ data }) => {
    setUser(data);
    if (typeof onSuccess === 'function') {
      onSuccess(data);
    }
  }).catch(({ response }) => {
    let error = 'An unknown error occurred.'
    if (response && response.data) {
      // Invalid credentials
      if (
        response.data.code.indexOf('invalid_username') >= 0 ||
        response.data.code.indexOf('incorrect_password') >= 0
      ) {
        error = 'Invalid credentials';
      }

      // User not approved
      if (response.data.code.indexOf('wpau_confirmation_error') >= 0) {
        error = 'Your account has not yet been approved';
      }
    }

    if (typeof onError === 'function') {
      onError(error);
    }
  });
}
/**
 * Handle create user
 */
export const handleCreateUser = (username, email, password, onSuccess, onError) => {
  axios.post(endpoints.SIGN_UP, { username, email, password }).then(({ data }) => {
    setUser(data);
    if (typeof onSuccess === 'function') {
      onSuccess(data);
    }
  }).catch(({ response }) => {
    let error = 'An unknown error occurred.'
    if (response && response.data) {
      error = response.data.message;
    }

    if (typeof onError === 'function') {
      onError(error);
    }
  });
}

/**
 * Check if user is logged in
 */
export const isLoggedIn = () => {
  const user = getUser()
  return !!user.token;
}

/**
 * Validate user
 */
export const validateAuthenticatedUser = (onSuccess, onError) => {
  const user = getUser();

  return axios({
    url: endpoints.VALIDATE_AUTH,
    method: 'POST',
    headers: {
      common: {
        Authorization: `Bearer ${user.token}`
      },
    }
  }).then(({ data }) => {
    if (typeof onSuccess === 'function') {
      onSuccess(data);
    }
  }).catch(() => {
    if (typeof onError === 'function') {
      onError();
    }
  });
}

/**
 * Log user out
 */
export const logout = callback => {
  setUser({})
  if (typeof callback === 'function') {
    callback();
  }
}
