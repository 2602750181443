import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import isBrowser from '../utils/isBrowser';
import { isLoggedIn, validateAuthenticatedUser } from '../services/auth'
import Transition from '../components/Transition'
import { Logo } from '../assets/svgs/Logo'

const Layout = ({ children, location }) => {
  const [isReady, setIsReady] = useState(false);
  const onProjectedRoute = location.pathname.match(/^\/collection\//);

  /**
   * If user is not logged in
   */

   if (onProjectedRoute) {
     if (isBrowser() && !isLoggedIn()) {
       navigate(`/login/`);
       return null;
     /**
      * Validate logged in user
      */
     } else if (isLoggedIn()) {
       validateAuthenticatedUser(() => {
         // Validation succeeded
         setIsReady(true);
       }, () => {
         // Validation failed
         navigate(`/login/`);
         setIsReady(true);
       })
     }
   }

  /**
  * Wait until user is validated before rendering
  */
  if (!isReady && onProjectedRoute) {
    return null;
  }

  return (
    <Transition location={location}>
      <React.Fragment>
        <Helmet>
          <meta http-equiv="Content-Type" content="text/html;charset=utf-8" />
          <title>ECCO SS20</title>
          <link rel="stylesheet" href="https://use.typekit.net/byf7ppr.css" />
          <meta name="robots" content="noindex, nofollow" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
        {location.pathname === '/' && <StyledLogo />}
        {children}
      </React.Fragment>
    </Transition>
  )
}

export default Layout

const StyledLogo = styled(Logo)`
  position: absolute;
  top: 50px;
  left: 50%;
  margin-left: -36px;
  z-index: 1200;
`
