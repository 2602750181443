import React from 'react'

export const Logo = props => (
  <svg width="73px" height="20px" viewBox="0 0 73 20" {...props}>
    <path
      d="M24.0330262,2.67583643 C25.0009194,2.30535316 25.9858681,2.12542751 27.0859406,2.12542751 C29.8534713,2.12542751 32.3284874,3.41182156 33.9446324,5.4264684 C34.1572367,5.69182156 34.297429,6.03687732 34.297429,6.40133829 C34.297429,6.90951673 34.0793112,7.32736059 33.7039456,7.60639405 L33.6868167,7.63888476 C33.4340735,7.82743494 33.1159758,7.93821561 32.7772205,7.93821561 C32.2983464,7.93821561 31.8696828,7.71665428 31.5902538,7.3664684 C31.26157,6.95531599 30.8871601,6.60208178 30.4615106,6.29152416 C29.5122165,5.60490706 28.3433343,5.19933086 27.0859406,5.19933086 C26.3643212,5.19933086 25.7165106,5.32282528 25.0808298,5.56728625 C23.8752638,6.02669145 22.8293716,6.88817844 22.1584773,7.9804461 C21.6137341,8.87442379 21.2921078,9.92750929 21.2921078,11.0507807 C21.2921078,11.8766543 21.4714834,12.6598513 21.773996,13.3704089 C22.1785468,14.3218587 22.8168006,15.1441636 23.627005,15.7561338 C24.5918842,16.485948 25.786423,16.9164312 27.0859406,16.9164312 C28.8956495,16.9164312 30.5334079,16.0432714 31.5942971,14.7279554 C31.8741672,14.3695911 32.3013605,14.1434201 32.7832487,14.1434201 C33.6235206,14.1434201 34.3075005,14.8326394 34.3075005,15.6824535 C34.3075005,16.047881 34.1813494,16.3843123 33.9677895,16.6495911 C32.3485569,18.6902602 29.8750846,19.9913755 27.0859406,19.9913755 C22.2087613,19.9913755 18.2522054,15.9914498 18.2522054,11.0507807 C18.2522054,9.31100372 18.7512226,7.68312268 19.6060504,6.30527881 C20.6242276,4.66869888 22.2187593,3.36907063 24.0330262,2.67583643"
      fill="#FFFFFE"
    />
    <path
      d="M42.312432,2.68394052 C43.2828983,2.3139777 44.2668177,2.13204461 45.3668902,2.13204461 C48.1358912,2.13204461 50.6094371,3.4194052 52.2286697,5.43717472 C52.4377452,5.70141264 52.5769084,6.04193309 52.5769084,6.41144981 C52.5769084,6.91613383 52.3593787,7.33345725 51.9864391,7.61650558 L51.9687956,7.64698885 C51.7150232,7.83605948 51.396852,7.9469145 51.0566999,7.9469145 C50.576282,7.9469145 50.153573,7.72327138 49.8751732,7.37256506 C49.5470775,6.96394052 49.1681098,6.60713755 48.7454743,6.30319703 C47.79471,5.61144981 46.6272981,5.2069145 45.3668902,5.2069145 C44.6467412,5.2069145 44.0000332,5.33598513 43.3647936,5.57739777 C42.1601833,6.03687732 41.1103948,6.89635688 40.4409708,7.99055762 C39.8972568,8.88260223 39.5731309,9.93613383 39.5731309,11.0690706 C39.5731309,11.8883271 39.7570645,12.6684758 40.059577,13.3779926 C40.462143,14.3304833 41.0992941,15.1513011 41.9109688,15.7622305 C42.8743041,16.4945725 44.0734008,16.9235688 45.3668902,16.9235688 C47.180569,16.9235688 48.8143575,16.0534572 49.8781873,14.734052 C50.1575428,14.37829 50.58628,14.1495167 51.0666979,14.1495167 C51.9130715,14.1495167 52.5899204,14.8382156 52.5899204,15.6895167 C52.5899204,16.0549442 52.4622991,16.3944981 52.2512387,16.6556877 C50.6309768,18.6958364 48.1530201,20 45.3668902,20 C40.489711,20 36.5332286,16.0000743 36.5332286,11.0690706 C36.5332286,9.31814126 37.0322457,7.68869888 37.8881027,6.3133829 C38.9092205,4.67427509 40.4997825,3.37516729 42.312432,2.68394052"
      fill="#FFFFFE"
    />
    <path
      d="M56.8032628,16.6495911 C58.4219809,18.6902602 60.8959678,19.9913755 63.6890816,19.9913755 C68.564717,19.9913755 72.5173031,15.9914498 72.5173031,11.0507807 C72.5173031,9.31100372 72.0251964,7.68312268 71.1704421,6.30527881 C70.8995408,5.87375465 70.4206667,5.57390335 69.8743061,5.57390335 C69.0396949,5.57390335 68.3572588,6.26356877 68.3572588,7.1133829 C68.3572588,7.41472119 68.4657664,7.74260223 68.6125015,7.9804461 C69.1627583,8.87442379 69.4783565,9.92750929 69.4783565,11.0507807 C69.4783565,11.8766543 69.2989809,12.6598513 68.9949245,13.3704089 C68.5878006,14.3218587 67.9511641,15.1441636 67.1480906,15.7561338 C66.1756395,16.485948 64.9811742,16.9164312 63.6890816,16.9164312 C61.873859,16.9164312 60.2361742,16.0432714 59.177858,14.7279554 C58.3616989,13.7185874 57.8903233,12.4596283 57.8903233,11.0507807 C57.8903233,9.09449814 58.8435871,7.35732342 60.307998,6.29152416 C61.2543515,5.60490706 62.4256596,5.19933086 63.6890816,5.19933086 C64.4032759,5.19933086 65.0599819,5.32282528 65.6941923,5.56728625 C65.855998,5.62572491 66.0339033,5.65776952 66.2118087,5.65776952 C67.0520806,5.65776952 67.7331198,4.96959108 67.7331198,4.11977695 C67.7331198,3.45851301 67.3190121,2.89182156 66.7380262,2.67583643 C65.773147,2.30535316 64.783567,2.12542751 63.6890816,2.12542751 C60.9160373,2.12542751 58.4405065,3.41182156 56.8189215,5.4264684 C55.5871108,6.96394052 54.8499063,8.92223048 54.8499063,11.0507807 C54.8499063,13.1716729 55.5871108,15.11829 56.8032628,16.6495911"
      fill="#FFFFFE"
    />
    <path
      d="M5.78067372,2.67583643 C5.20277543,2.8933829 4.78815307,3.45903346 4.78815307,4.12386617 C4.78815307,4.97412639 5.46808963,5.66282528 6.30836153,5.66282528 C6.49023666,5.66282528 6.6666717,5.62929368 6.82597784,5.56877323 C7.46268781,5.3263197 8.11645317,5.20185874 8.83763142,5.20185874 C10.0909819,5.20185874 11.2744199,5.63182156 12.2252578,6.32148699 L3.51921752,13.3734572 C3.21773414,12.6628996 3.04431319,11.8812639 3.04431319,11.0665428 C3.04431319,9.93263941 3.35844109,8.87695167 3.90465458,7.98401487 C4.05396274,7.74565056 4.16445519,7.41776952 4.16445519,7.11434944 C4.16445519,6.26460967 3.48606244,5.57895911 2.64439376,5.57895911 C2.10207654,5.57895911 1.62320242,5.87628253 1.35531521,6.3067658 C0.498943605,7.68312268 0,9.31204461 0,11.0665428 C0,15.9939777 3.95640886,19.9939033 8.83763142,19.9939033 C11.6227321,19.9939033 14.1022326,18.6917472 15.7209507,16.6495911 C15.9334814,16.3858736 16.0566183,16.0509294 16.0566183,15.6839405 C16.0566183,14.8326394 15.3741823,14.1464684 14.5349396,14.1464684 C14.0549627,14.1464684 13.6218147,14.3695911 13.346429,14.7294424 C12.2835549,16.0463197 10.6462377,16.9179926 8.83763142,16.9179926 C7.54002518,16.9179926 6.3454864,16.4798513 5.38060725,15.7536059 L15.4445358,7.6129368 C15.818431,7.32780669 16.0495609,6.91159851 16.0495609,6.40483271 C16.0495609,6.03992565 15.9073837,5.6927881 15.6968379,5.43204461 C14.079149,3.41330855 11.6041329,2.12542751 8.83763142,2.12542751 C7.73807351,2.12542751 6.75158107,2.3063197 5.78067372,2.67583643"
      fill="#FFFFFE"
    />
    <path
      d="M69.5215831,1.92817844 C69.5215831,1.07843866 70.2070332,0.38669145 71.0473051,0.38669145 C71.8875035,0.38669145 72.5715569,1.07843866 72.5715569,1.92817844 C72.5715569,2.77903346 71.8875035,3.47026022 71.0473051,3.47026022 C70.2070332,3.47026022 69.5215831,2.77903346 69.5215831,1.92817844 M69.1573182,1.92817844 C69.1573182,2.98178439 70.004574,3.83821561 71.0473051,3.83821561 C72.0895217,3.83821561 72.9358218,2.98178439 72.9358218,1.92817844 C72.9358218,0.875613383 72.0895217,0.0182156134 71.0473051,0.0182156134 C70.004574,0.0182156134 69.1573182,0.875613383 69.1573182,1.92817844"
      id="Fill-7"
      fill="#FFFFFE"
    />
    <path
      d="M70.4422064,0.982899628 L70.8448459,0.982899628 C71.2463092,0.982899628 71.7252568,0.969665428 71.7252568,1.49464684 C71.7252568,1.71933086 71.5779335,1.90379182 71.3382759,1.93583643 L71.3382759,1.94037175 C71.4458278,1.94855019 71.5001551,2.05219331 71.537721,2.13553903 L71.8372931,2.87457249 L71.4201712,2.87457249 L71.1935992,2.28349442 C71.1433152,2.14319703 71.0945015,2.08884758 70.9352689,2.08884758 L70.8146314,2.08884758 L70.8146314,2.87457249 L70.4422064,2.87457249 L70.4422064,0.982899628 M70.8146314,1.7935316 L70.938283,1.7935316 C71.1216284,1.7935316 71.3353353,1.76505576 71.3353353,1.52825279 C71.3353353,1.29903346 71.1246425,1.27918216 70.938283,1.27918216 L70.8146314,1.27918216 L70.8146314,1.7935316"
      id="Fill-8"
      fill="#FFFFFE"
    />
  </svg>
)
